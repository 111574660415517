import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.79.4_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/buildhome/repo/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}