import revive_payload_client_70mnaDaQNl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.79.4_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1TmMPkEo1e from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.79.4_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wftMObzf02 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.79.4_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Ap9vnhpAto from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.79.4_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_RVahVoejou from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.79.4_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7Oy9olrtcj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.79.4_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_gTRdedK54b from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.79.4_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_RJxVzVMQrP from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@2.79.2_typescript@5.6.2_vue@3.5.10/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_Ph1esqYzvM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.79.4_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_qxmGYkWS9A from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.2_vite@5.4.8_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_VL8psc7I9t from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.2_vite@5.4.8_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_mqQQeyf3x2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@2.79.2/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import slideovers_7mAJrBL9JI from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_qrcode@1.5.4_rollup@2.79.2_vite@5.4.8_vue@3.5.10/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_e6y0nttRDX from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_qrcode@1.5.4_rollup@2.79.2_vite@5.4.8_vue@3.5.10/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_yjomYwn1di from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_qrcode@1.5.4_rollup@2.79.2_vite@5.4.8_vue@3.5.10/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_SNP3x2MHEe from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@2.79.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_ntQFtb5gps from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.2_rollup@2.79.2_vite@5.4.8_vue@3.5.10/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/opt/buildhome/repo/.nuxt/floating-vue.mjs";
import plugin_client_cgEYQl7zYG from "/opt/buildhome/repo/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_rollup@2.79.2_vue@3.5.10/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_wofILYXtuU from "/opt/buildhome/repo/node_modules/.pnpm/@hypernym+nuxt-anime@2.1.1/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import plugin_TFCEIBHo6D from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_pinia@2.2.4_rollup@2.79.2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import calendar_client_YJGsTbn2VI from "/opt/buildhome/repo/plugins/calendar.client.js";
import content_GArnI6Y6ho from "/opt/buildhome/repo/plugins/content.js";
import tracker_client_GsGCjPbvL8 from "/opt/buildhome/repo/plugins/tracker.client.js";
import vconsole_client_Zu3Hk7iamm from "/opt/buildhome/repo/plugins/vconsole.client.js";
import wallet_client_dYVbaY1ZcL from "/opt/buildhome/repo/plugins/wallet.client.js";
export default [
  revive_payload_client_70mnaDaQNl,
  unhead_1TmMPkEo1e,
  router_wftMObzf02,
  payload_client_Ap9vnhpAto,
  navigation_repaint_client_RVahVoejou,
  check_outdated_build_client_7Oy9olrtcj,
  chunk_reload_client_gTRdedK54b,
  plugin_vue3_RJxVzVMQrP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Ph1esqYzvM,
  pwa_icons_qxmGYkWS9A,
  pwa_client_VL8psc7I9t,
  nuxt_plugin_mqQQeyf3x2,
  slideovers_7mAJrBL9JI,
  modals_e6y0nttRDX,
  colors_yjomYwn1di,
  plugin_client_SNP3x2MHEe,
  plugin_ntQFtb5gps,
  floating_vue_EIcJ7xiw0h,
  plugin_client_cgEYQl7zYG,
  plugin_wofILYXtuU,
  plugin_TFCEIBHo6D,
  calendar_client_YJGsTbn2VI,
  content_GArnI6Y6ho,
  tracker_client_GsGCjPbvL8,
  vconsole_client_Zu3Hk7iamm,
  wallet_client_dYVbaY1ZcL
]